import React from 'react';

export default function PlaceHolder() {
  return (
    <div className="watch-trans-place-holder d-flex justify-content-center align-items-center">
      <div className="sk-flow">
        <div className="sk-flow-dot" />
        <div className="sk-flow-dot" />
        <div className="sk-flow-dot" />
      </div>
    </div>
  );
}
